<template lang="pug">
  svg#topFavorites(width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M17.4133 6.50006H10.761L8.70666 6.10352e-05L6.65228 6.50006H0L5.38052 10.5001L3.32614 17.0001L8.70666 13.0001L14.0872 17.0001L12.0328 10.5001L17.4133 6.50006ZM8.70666 10.8001L6.65228 12.3001L7.4349 9.80006L5.38052 8.30006H7.92404L8.70666 5.90006L9.48928 8.30006H12.0328L9.97842 9.80006L10.761 12.3001L8.70666 10.8001Z')
</template>

<script>
export default {
  name: 'TopFavoritesSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#94b8af',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>